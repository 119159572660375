@import "../../variables.sass"

.AppFrame
  .Menu
    position: sticky

.Menu
  top: 0px
  position: sticky
  overflow: auto
  min-width: $menuMinWidth
  width: $menuMinWidth
  display: flex
  flex-direction: column
  font-size: 13px
  font-weight: 400
  background: #FBFDFF
  border-right: 1px solid rgba(0, 0, 0, 0.1)
  height: 100vh

  .Icon
    width: 20px

  .MuiTypography-body1
    font-size: 13px
    font-weight: 400

  .MuiDivider-root
    border-color: rgba(0, 0, 0, 0.08)

  &:last-child
    align-self: flex-end

  .MenuItem
    &.MuiListItemButton-root
      font-size: 13px
      transition: 0.3s background-color
      padding: 2px 6px

      &:hover, &.Mui-selected
        transition: 0.3s background-color
        background-color: rgb(236 239 243)

        .MuiTypography-body1
          color: black

  a
    text-decoration: none
    color: unset

    &:hover
      color: black

  li:last-of-type
    // width: 64px
    .Branding
      text-align: left
      margin: 0px !important
      .logoH
        margin: 0 auto
        height: 32px
      .logoV
        margin: 0 auto
        height: 80px

  .item-divider
    margin: 8px 0

.Icon
  width: 20px

.Electron
  .Menu
    position: sticky
    height: calc(100vh - $electronHeaderHeight)
