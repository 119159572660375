@import "../../variables.sass"

.TeamPage
  position: relative
  display: flex

  &__content
    padding: 32px 48px

  .PageTitleGrid
    display: flex
    flex: 1
    align-items: center
    .PageTitle
      max-width: 50%

  .PageHeader
    .PageDate
      font-size: 14px

    .DatePickerContainer
      flex: 1
      align-items: center
      .DatePickerTextField
        max-width: 150px
        background-color: white
