@import "../../variables.sass"

.Nav
  position: absolute
  left: 32px
  top: 24px
  color: white
  font-size: $mainFont
  text-align: left
  z-index: 999

  a
    color: white
    text-decoration: none
    display: block
    line-height: 18px
    vertical-align: middle

  .NavItems
    display: flex
    align-items: center

    .CurrentRoom
      border-left: 2px solid rgba(255, 255, 255, 0.4)
      padding-left: 12px
      margin-left: 12px

  .brought
    font-size: 14px
    opacity: 0.4
