@import "../../../variables.sass"

.MultipleChoice
  &.MuiButton-root
    border-radius: 30px
    padding: 12px 16px 12px 12px
    font-weight: normal

  .MultipleChoice__Icon
    height: 20px
    width: 20px
    border-radius: 10px
    border: 1px solid #34314122
    margin-right: 8px

    &_on
      background: #343141
      border: 1px solid #343141
