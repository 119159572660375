@import "../variables.sass"

.AppHome
  position: absolute
  min-width: 100vw
  min-height: 100vh
  background-repeat: no-repeat
  // background-image: url("../assets/bg.jpg")
  background-size: cover
  display: flex

  &__content
    flex-direction: column
    align-items: center
    justify-content: center

    .AppHome__header
      max-width: none

    .AppHome__body
      max-width: none
      margin-top: 0px
      margin-bottom: 0px

  h1
    margin: 0

  .Nav
    color: black
    text-align: left

  .greeting
    margin: 0px 0px 32px
    text-align: left
    font-weight: 500
    font-size: $mainFont

  .Rooms
    align-items: stretch
    justify-content: stretch

    .Room
      text-decoration: none
      display: flex
      flex-direction: column
      align-items: stretch
      justify-content: stretch
      color: #555
      flex: 1

      a
        text-decoration: none

      .RoomCard
        display: flex
        flex-direction: column
        flex: 1
        position: relative
        background-position: center
        background-size: cover
        border-radius: 6px
        margin-bottom: 20px
        box-shadow: 0px 16px 32px -24px rgba(0, 0, 0, 0.48)
        transfrom: scale(1, 1)
        transition: box-shadow 0.4s, transform 0.4s
        font-family: $accentFont
        font-size: 24px
        font-weight: 700
        padding-bottom: 12px
        justify-content: space-between
        color: white

        &:hover
          box-shadow: 0px 36px 60px -48px rgba(0, 0, 0, 0.72)
          transform: scale(1.03, 1.03)
          transition: box-shadow 0.8s, transform 0.8s

          .RoomCard__cta
            transform: scale(1.01, 1.01)
            transition: transform 0.6s

        &__bottom
          width: 100%

        &__cta
          transform: scale(1, 1)
          transition: transform 0.6s
          border-radius: 40px
          color: #333
          background: white
          text-transform: none
          font-weight: 700
          box-shadow: 0px 16px 32px -16px rgba(0, 0, 0, 0.72)

        &__badge
          margin: 20px
          height: 40px
          align-self: flex-start

        &__socialProof
          margin: 12px 0px
          display: inline-block

          .ProfilePictures
            justify-content: left

@media (max-width: 640px)
  .AppHome
    h1
      font-size: 24px

    h3
      font-size: 18px

    .greeting
      margin-bottom: 32px

    .Rooms
      margin: 0 16px

.Electron.AppHome
  min-height: auto
  height: calc(100vh - $electronHeaderHeight)
  .AppHome
    &__content
      .AppHome__body
        margin-bottom: 0px
