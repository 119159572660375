.TemplateList, .TemplateVariantList
  min-width: 100vw
  min-height: 100vh
  display: flex

  .PageHeader
    max-width: 1280px

  &__content
    padding: 16px 48px

  .TemplateCard
    border-radius: 8px
    background: white
    border: 1px solid rgba(0, 0, 0, 0.1)
    word-break: break-all

  .MessageRoleToggle
    min-width: unset
    background: #d0e1ea
    height: 44px
    width: 44px
    padding: 8px
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center

  .MessageRoleToggleSystem
    background: #d0e1ea

  .MessageRoleToggleUser
    background: #ecead4
    &:hover
      background: #ecead4

  .MessageRoleToggleAssistant
    background: #c9d0e4
    &:hover
      background: #dbe2f5

  .CurlBox
    div.w-tc-editor-preview, textarea.w-tc-editor-text
      word-break: keep-all !important
      white-space: pre !important

  .OutputPromptParams
    .MuiChip-root
      font-size: 10px

  .VariantModelParams
    .MuiChip-root
      font-size: 12px

.Mobile
  .TemplateList, .TemplateVariantList
    &__header
      margin-top: 0px
