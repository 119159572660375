@import "../../variables.sass"

.Models
  min-width: 100vw
  min-height: 100vh
  display: flex

  .PageHeader
    max-width: 1280px

  &__content
    padding: 16px 48px

  .ModelCard
    border-radius: 8px
    background: white
    border: 1px solid rgba(0, 0, 0, 0.1)
    height: 100%
    word-break: break-word

    a
      text-decoration: none
      color: $mainColorDark
      display: block
      height: 100%

.CreateDatasetModal
  .dropzone
    background: #fff9f2
    padding: 32px 16px 32px
    border: 1px dashed transparentize($mainColor, 0.8)
    width: 100%
    text-align: center
    border-radius: 8px

.Mobile
  .Models
    &__header
      margin-top: 0px

    &__content
      padding: 16px 16px
