@import "./variables.sass"

body
  margin: 0
  font-family: $fontFamily
  letter-spacing: 0.05em
  font-weight: 400
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

a
  &:active
    text-decoration: none
