
@import "../../variables.sass"

.PromptEditor
  width: 100%
  position: relative

  &.RawMode
    .Editor
      display: none

    .Preview
      width: 100%
      padding: 8px 8px 0px

      .Messages, .SectionHeader, .PromptBox
        width: 100%
        max-width: 960px
        margin: 0 auto

      .PromptBoxWrapper
        margin: 0px
        left: 0px

        .PromptBox
          padding: 0px 24px

      .SectionHeader
        margin-bottom: 16px

  &.Mobile
    .Preview, .Editor
      height: -webkit-fill-available
      padding: 8px 0px 0px

      .PromptBox
        padding: 0px 0px

      .SectionHeader
        margin-bottom: 16px
        padding: 0px 12px

      .PromptBoxWrapper
        bottom: env(safe-area-inset-bottom)

    &.RawMode
      .PromptBoxWrapper
        margin: 0px
        padding: 12px 12px

    &.GuideMode
      .Preview
        display: none

      .Editor
        width: 100%
        padding: 12px 12px 0px

        .PromptBoxWrapper
          padding: 12px 12px

        .SectionHeader
          margin-bottom: 16px

  // show response view as a menu overlap
  &.MobileShowResponse
    position: relative

    &.GuideMode, &.RawMode
      .Preview
        display: block
        position: absolute
        top: 0px
        bottom: 0px
        left: 0px
        right: 0px
        width: auto
        height: auto
        z-index: 20000
        overflow-y: auto
        background: white

  .Editor
    position: relative
    height: 100vh
    overflow: auto
    flex: 0 0 auto
    padding: 8px 12px 0px
    background: white
    width: 50%

    &::-webkit-scrollbar
      display: none

  .PromptBoxWrapper
    position: fixed
    width: 100%
    bottom: 0px
    background: #57595c
    color: white
    padding: 12px 24px
    margin: 0px -12px 0px
    border-top: 1px solid rgba(0, 0, 0, 0.1)
    z-index: 1000

    img
      filter: brightness(1000%)

  .PromptPreview
    padding: 8px 12px
    border: 1px solid rgba(0, 0, 0, 0.1)
    border-radius: 8px
    background: #323232
    color: white
    flex: 1

  div.PromptPreview
    max-height: 12em
    overflow: auto

  textarea.PromptPreview
    font-family: $fontFamily
    outline: none

  .Preview
    height: 100vh
    overflow: auto
    flex: 0 0 auto
    padding: 24px 0px 0px
    width: 50%

    .SectionHeader
      padding: 0px 24px

    .Messages
      padding: 16px 24px 48px

    .Message, .UserMessage
      padding: 16px
      background: white
      border-radius: 12px
      white-space: pre-wrap
      max-width: 80%

    .UserMessage
      background: #cde3cd
      align-self: flex-end

  .Control
    color: $mainColor

    > .Nav
      color: $mainColor

      > a
        color: $mainColor

  .Selections
    &::-webkit-scrollbar
      display: none

  .NavItems
    display: flex
    align-items: center

    .CurrentRoom
      border-left: 2px solid rgba(255, 255, 255, 0.4)

  &__content
    padding: 32px 48px

  &__body
    .MuiGrid-container
      width: 100%

.ElectronApp
  .Editor
    height: calc(100vh - $electronHeaderHeight)
