@import "../../../variables.sass"

.JournalPage
  position: relative
  display: flex

  &__content
    padding: 32px 48px

  .JournalDate
    color: #343141
    font-size: 18px
    font-weight: 400
    padding-right: 20px

  .JournalFilterContainer
    flex: 1
    align-items: center

    .JournalFilterLabel
      color: #555555
      font-size: 16px
      font-weight: normal
      padding-right: 20px
