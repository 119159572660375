@import "../../variables.sass"

.AppFrame
  display: flex

  &__header
    padding: 4px 0px
    background-color: rgba(255, 255, 255, 0)
    position: relative
    z-index: 10
    max-width: 1024px
    margin: 16px auto

  &__body
    max-width: 1024px
    margin: 16px auto

  &.Sticky
    .AppFrame__header
      margin: 0 auto
      max-width: none
      padding: 12px 32px
      position: fixed
      top: 0
      left: 0
      right: 0
      background-color: rgba(255, 255, 255, 1)
      transition: background-color 0.4s
      border-bottom: 1px solid rgba(0, 0, 0, 0.08)
      box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.05), 0px 8px 20px -10px rgba(0, 0, 0, 0.1)

  .PageHeader
    margin: 0

    h1
      margin: 0px

  .PageSubtitle
    font-size: 18px
    font-weight: 500

  .PageTitle
    margin-bottom: 0px
    font-weight: 400
    font-size: 16px

  &.Mobile
    position: relative

    .PageHeader
      flex-direction: column
      gap: 16px

    .Menu
      position: fixed
      left: 0
      top: 0
      right: 0
      bottom: 0
      width: auto
      height: auto
      overflow: auto
      border-right: none
      z-index: 100

.Workspace.AppFrame
  .AppFrame__content
    position: relative
    display: flex
    flex-direction: column
    height: 100vh
    max-height: 100vh
    padding: 0px
    overflow: hidden

  .AppFrame__header
    padding: 12px 32px
    top: 0
    left: 0
    right: 0
    background-color: rgba(255, 255, 255, 1)
    transition: background-color 0.4s
    max-width: unset
    margin: 0px
    height: 56px
    border-bottom: 1px solid $borderColor
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: center
    flex-shrink: 0

    .PageHeader
      display: flex
      align-items: center
      max-width: unset

  .AppFrame__body
    width: 100%
    max-width: unset
    margin: 0px
    display: flex
    flex-direction: column
    overflow: auto
    padding: 16px 32px
    flex: 1

.AppFrame.Electron
  height: calc(100vh - $electronHeaderHeight)
  overflow: auto

  .Preview, .Editor
    height: calc(100vh - $electronHeaderHeight)

.Workspace.AppFrame.Electron
  .AppFrame__content
    height: calc(100vh - $electronHeaderHeight)
