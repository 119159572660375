.successRings
  width: 100vw
  height: 100vh
  overflow: hidden
  position: absolute

  .ring
    position: absolute
    width: 30vw
    height: 30vw
    border-radius: 20vw
    border: 8px solid white
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    opacity: 0
    animation: success-ring 1.6s ease-out forwards

  .smallRing
    animation-duration: 2s

  .largeRing
    width: 40vw
    height: 40vw

@keyframes success-ring
  0%
    transform: translate(-50%, -50%) scale(0.2, 0.2)
    opacity: 0

  8%
    transform: translate(-50%, -50%) scale(0.2, 0.2)
    opacity: 0.6

  100%
    transform: translate(-50%, -50%) scale(1, 1)
    opacity: 0
