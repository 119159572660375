.MultipleChoices
  display: flex
  flex-wrap: wrap
  min-width: 240px
  max-width: 560px
  justify-content: center
  align-items: center

  &.Left
    align-items: flex-start
    justify-content: flex-start
    max-width: unset
    flex: 0

  &.Vertical
    flex-wrap: nowrap
    flex-direction: column
    max-width: unset
    align-items: stretch

    .MultipleChoice
      margin-right: 0px

  .MultipleChoice
    padding: 4px 16px
    border-radius: 90px
    border: 1.5px solid #F1F1F1
    border-color: #dcdce7
    margin-bottom: 12px
    margin-right: 12px
    background: #fff
    color: black
    transition: 0.2s background, 0.2s border-color, 0.2s color
    cursor: pointer
    font-weight: 400
    text-align: center
    font-size: 14px

    span
      font-size: 14px

    &:hover
      background: #E4DDFF66
      border-color: #7258D566
      color: #331E83
      transition: 0.3s background, 0.3s border-color, 0.3s color

    &.selected
      background: #E4DDFF
      border-color: #7258D5
      color: #331E83
