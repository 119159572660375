.RecentUsers
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start

  .RecentUser
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 12px 20px

    .avatar
      width: 48px
      height: 48px
      border-radius: 24px
      border: 2px solid white
      overflow: hidden

      img
        object-fit: cover
        width: 100%

    .info
      margin-left: 12px
      text-align: left
      color: rgba(255, 255, 255, 0.4)

      b
        color: rgba(255, 255, 255, 0.6)
        font-weight: 600

    span
      margin-left: 16px
