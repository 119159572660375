@import "../../variables.sass"

$duration: 0.4s

.WeekdayPicker
  border-radius: 12px

  .MuiToggleButton-root
    border-radius: 20px
    border: 0
    text-transform: none
    flex: 1
    width: 50px
    height: 40px
    line-height: 40px
    font-size: 14px
    transition: background $duration

    &:hover
      transition: background $duration

    &.Mui-selected
      background: $mainColor
      transition: background $duration
      color: white

      &:hover
        background: $mainColor
        transition: background $duration
        color: white

  &__day, &__selectedDay
    border-radius: 20px
    border: 0

.WeekdayPickerPopover, .HourMinutePickerPopover, .TimezonePickerPopover, .NumberPickerPopover
  .MuiBackdrop-invisible
    background: rgba(0, 0, 0, 0.4)

  .MuiPaper-root
    border-radius: 12px

  .MuiSlider-markLabel, .MuiSlider-valueLabelLabel
    font-size: 12px

  .MuiSlider-valueLabelOpen
    background: $mainColor
    border-radius: 4px

.HourMinutePickerPopover
  &ValueDisplay
    background: $mainColor
    color: white
    height: 30px
    line-height: 30px
    vertical-align: middle
    padding: 0px 10px
