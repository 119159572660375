@import "../../variables.sass"

.VideoRoom
  color: white
  position: relative
  height: 100vh
  width: 100vw
  position: absolute
  overflow: hidden
  display: flex
  align-items: center
  justify-content: center

  .Bg
    height: 100vh
    width: 100vw
    position: absolute
    z-index: -1
    overflow: hidden
    background: black

    .Shade
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      background: rgba(0, 0, 0, 0.6)
      backdrop-filter: blur(4px)
      transition: background 0.4s, backdrop-filter 0.4s

      &.inProgress
        background: rgba(0, 0, 0, 0.2)
        backdrop-filter: blur(0px)
        transition: background 0.4s, backdrop-filter 0.4s

  .ClassVideo
    height: 100vh
    width: 100vw
    position: absolute
    top: 0
    left: 0

  .Control
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center

    .Intro
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      max-width: 640px

    .Social
      position: absolute
      right: 32px
      bottom: 24px

  .class-subtitle
    position: relative
    overflow: hidden
    height: 30px
    font-size: 24px
    line-height: 30px
    text-align: center
    font-family: $accentFont
    font-weight: 700
    color: white
