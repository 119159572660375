@import "../../../../variables.sass"

.TeamMemberDialogLoading
  background-color: yellow !important
.TeamMemberDialog
  .MuiDialog-container
    .MuiPaper-root
      max-width: 720px
      min-height: 720px
.errorMessageContainer
  display: flex
  flex: 1
  align-items: center
  padding-top: 16px
  .errorMessage.MuiTypography-root
    color: red

.team-member-invited
  border: $teamMemberInvited 1px solid
  border-radius: 60px
.team-member-active
  border: $teamMemberActive 1px solid
  border-radius: 60px
.team-member-inactive
  border: $teamMemberInactive 1px solid
  border-radius: 60px
.team-member-deactivated
  border: $teamMemberDeactivated 1px solid
  border-radius: 60px
