
@import "../../variables.sass"

.AppBuilder
  width: 100%
  position: relative

  .SectionHeader
    position: fixed
    width: 100%
    backdrop-filter: blur(10px)
    padding: 8px 24px
    z-index: 3000
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  .Stage
    padding: 24px
    flex: 1
    max-width: 480px

    .Text
      border: 1px solid rgba(0, 0, 0, 0.2)
      border-radius: 6px
      width: 100%
      padding: 4px
      font-family: $fontFamily

    .Output
      white-space: break-spaces
      overflow: auto
      max-width: 100%
      max-height: 160px
      background: white
      border-radius: 6px

.WebhookURL
  padding: 4px 10px
  vertical-align: middle
  line-height: 24px
  max-width: 240px
  overflow-x: auto
  white-space: nowrap
  border-radius: 6px
  color: rgba(0, 0, 0, 0.5)
  border: 1px solid rgba(0, 0, 0, 0.2)
  background: rgba(0, 0, 0, 0.05)
