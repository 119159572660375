
@import "../../variables.sass"

.PromptEditor
  width: 100%
  position: relative

  .Editor
    min-width: 400px
    background: white
    flex: 0
    border-right: 1px solid rgba(0, 0, 0, 0.1)
    overflow-y: auto

    &::-webkit-scrollbar
      display: none

    .PromptBox
      border-top: 1px solid rgba(0, 0, 0, 0.1)

      .PromptPreview
        background: rgb(249 250 255 / 83%)
        color: #64697d

  .PromptBox
    background: #f7f8fd

  .Button
    padding-left: 14px
    padding-right: 14px
    background: rgb(252 253 253)
    color: rgb(66 63 98 / 73%)
    border: 1px solid rgba(0, 0, 0, 0.05)
    transform: translateY(0px)
    transition: all 0.4s

    &:hover
      background: white
      transform: translateY(-1px)
      color: rgb(22 20 47 / 76%)
      border: 1px solid white
      box-shadow: 0px 12px 16px -12px rgb(190 187 197), 0px 2px 4px -1px rgb(49 68 201 / 22%)
      transition: all 0.4s

  .PromptBoxWrapper
    position: fixed
    left: 50%
    transform: translateX(-50%)
    width: 100%
    max-width: 960px
    padding: 0px 24px
    bottom: 32px
    z-index: 1000

    .PromptBox
      padding: 6px 12px
      border-radius: 8px
      border-top: 1px solid rgb(253 253 253 / 52%)
      box-shadow: 0px 32px 48px -24px rgb(231 235 255 / 50%), 0px 10px 20px -8px rgb(136 129 152 / 37%), 0px 0px 2px 0px rgba(0, 0, 0, 0.06)
      backdrop-filter: blur(10px)
      background: rgb(255 255 255 / 67%)

      &.Dark
        background: rgb(21 17 23 / 73%)
        backdrop-filter: blur(10px)
        color: white
        padding: 6px 12px
        border-radius: 8px
        border-top: 1px solid rgb(93, 93, 93)
        box-shadow: 0px 32px 48px -32px rgb(0 0 0 / 50%), 0px 10px 20px -8px rgb(0 0 0 / 50%)

        img
          filter: brightness(1000%)

        .PromptPreview
          background: #323232
          color: white

  .PromptPreview
    padding: 8px 12px
    border: 1px solid rgba(0, 0, 0, 0.1)
    border-radius: 8px
    background: #ffffff9c
    color: #3c3c3c
    flex: 1
    letter-spacing: 0.65px
    font-size: 13px
    line-height: 20px
    max-height: 120px

  div.PromptPreview
    max-height: 12em
    overflow: auto

  textarea.PromptPreview
    font-family: $fontFamily
    outline: none

  .Preview
    overflow: auto
    padding: 0px

    .Messages, .PromptBox
      width: 100%
      max-width: 960px
      margin: 0 auto

    .PromptBoxWrapper
      margin: 0px

      .PromptBox
        padding: 6px 12px

  .SectionHeader
    position: fixed
    width: 100%
    backdrop-filter: blur(10px)
    padding: 8px 24px
    z-index: 3000
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  .MessagesWrapper
    display: flex
    flex-direction: column-reverse
    width: 100%

    .Messages
      width: 100%
      max-width: 960px
      margin: 0 auto
      padding: 16px 24px 48px
      line-height: 1.5em

    .Message, .UserMessage
      padding: 16px
      background: white
      border-radius: 12px
      word-break: break-word

    .MessageWrapper
      max-width: 480px

    .UserMessage
      background: #465bf4
      align-self: flex-end
      color: white
      white-space: pre-wrap

  .Control
    color: $mainColor

    > .Nav
      color: $mainColor

      > a
        color: $mainColor

  .Selections
    &::-webkit-scrollbar
      display: none

  .NavItems
    display: flex
    align-items: center

    .CurrentRoom
      border-left: 2px solid rgba(255, 255, 255, 0.4)

  &__content
    padding: 32px 48px

  &__body
    .MuiGrid-container
      width: 100%

.PromptEditor
  &.Mobile
    .Preview, .Editor
      overflow: unset
      padding: 8px 0px 0px

      .PromptPreview
        max-height: 80px

    .Preview
      .PromptBox
        padding: 6px 8px

    .Editor
      min-width: 100%

      .PromptBox
        padding: 12px 24px

    .SectionHeader
      margin-bottom: 16px
      padding: 0px 12px

    .PromptBoxWrapper
      bottom: env(safe-area-inset-bottom)

    &.RawMode
      .PromptBoxWrapper
        margin: 0px
        padding: 12px 12px

  // show response view as a menu overlap
  &.MobileShowResponse
    position: relative

    .Editor
      padding: 0px

    .Illustration
      display: none

    &.GuideMode, &.RawMode
      .Preview
        display: block
        position: absolute
        top: 0px
        bottom: 0px
        left: 0px
        right: 0px
        width: auto
        height: auto
        z-index: 20000
        overflow-y: auto
        background: white

.ElectronApp
  .Editor
    height: calc(100vh - $electronHeaderHeight)

.MessageMarkdown
  p, pre, code
    margin: 0
    padding: 0
  ul, ol
    margin: 0.5em 0
  code, pre
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
  h1, h2, h3, h4, h5, h6, h7
    margin: 1em 0 0.5em
  h1
    font-size: 22px
  h2
    font-size: 20px ,
  h3
    font-size: 18px
  h4
    font-size: 17px ,
  h5
    font-size: 15px ,
  h6
    font-size: 14px ,
  h7
    font-size: 13px
  table, thead, tbody
    text-align: left
    font-size: 15px
  p + *
    margin-bottom: 1em
