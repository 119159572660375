.Completion
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  height: 100vh

  &__fullscreen
    position: relative
    border-radius: 8px
    background: #f8f8f8
    padding: 24px 32px 24px
    color: black
    height: auto

  .Completion__SignInPrompt
    margin: 32px 0px 24px

  .SignInButtons
    position: relative

    .SignIn
      margin: 16px auto
      text-align: left

@media (max-width: 760px)
  .Completion__fullscreen
    position: fixed
    overflow: auto
    left: 12px
    top: 12px
    bottom: 12px
    right: 12px
    justify-content: flex-start
    border-radius: 8px
    background: #f8f8f8
    padding: 24px 32px 24px
    color: black
