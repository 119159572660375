@import "../../variables.sass"

.ElectronApp
  position: relative
  display: flex
  flex-direction: column
  height: 100vh
  width: 100vw
  overflow: hidden

.ElectronHeader
  width: 100%
  height: $electronHeaderHeight
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  background: white
  position: fixed
  top: 0px
  z-index: 999999999

  .MuiButtonBase-root
    border-radius: 0px

  .CloseButton
    border-radius: 0px
    &:hover
      background: red

      img
        filter: brightness(10000)

.DragHeader
  -webkit-app-region: drag

.ElectronAppBody
  position: absolute
  flex: 1
  top: $electronHeaderHeight
  width: 100%
  height: calc(100vh - $electronHeaderHeight)
