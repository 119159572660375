@import "../../variables.sass"

.BlinkRoom
  width: 100vw
  height: 100vh
  background: #070D0B
  background-image: linear-gradient(to bottom right, #070D0B, #111E1A)
  color: white
  overflow: hidden

  canvas
    opacity: 0.6
    filter: blur(32px)

  .Bg
    height: 100vh
    width: 100vw
    position: absolute
    z-index: 0
    overflow: hidden
    opacity: 1
    transition: opacity 2s

    &.inProgress
      opacity: 0
      transition: opacity 2s

    video, img, div
      object-fit: cover
      width: 100%
      height: 100%
      position: absolute
      z-index: -1
      top: 0
      left: 0

  .Control
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center

    .Social
      position: absolute
      right: 32px
      bottom: 24px

  .blink-subtitle
    position: relative
    overflow: hidden
    height: 30px
    font-size: 24px
    line-height: 30px
    text-align: center
    font-family: $accentFont
    font-weight: 700
    mix-blend-mode: soft-light
    color: black
