@import "../../variables.sass"

.MeetingInfoPopover
  &__MeetingIcon
    height: 24px

.BreakInfoPopover
  position: relative

  &__title
    display: flex
    align-items: center
    justify-content: flex-start

  &__icon
    height: 20px
    width: 20px
    font-size: 20px
    line-height: 20px

    svg
      font-size: 20px

.DailyCalendar
  display: flex
  flex-direction: column
  border-right: 0.5px solid #aaa
  position: sticky
  background: white
  max-width: 320px
  left: 0px
  top: 0px
  bottom: 0px
  height: 100vh

  .MuiTypography-body2
    font-size: 13px

  &__title
    width: 100%
    display: flex
    justify-content: space-between
    padding: 12px 8px 12px
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4)

  &__hours
    flex: 1
    overflow: auto
    cursor: pointer
    justify-content: flex-start

  &__hour
    position: relative
    font-size: 14px
    min-height: 44px
    border-top: 1px solid #DEDEDE
    padding: 8px 0px
    margin: 0px 16px
    flex-shrink: 0

    &:first-of-type
      border-top: 0

  &__currentMarker
    position: absolute
    height: 0px
    width: 100%
    left: 0px
    border-bottom: 1px solid #ff5205

  &__label
    font-size: 11px
    color: rgba(0, 0, 0, 0.4)
    width: 36px
    margin-right: 4px

  &__items
    flex: 1

  & &__item
    padding: 6px 10px
    border-radius: 4px
    font-family: $accentFont
    line-height: 1.1em
    font-weight: 400

    &_done
      text-decoration: line-through

    &_meeting
      color: #626680
      font-size: 14px
      background: #f1f3ff

      &:hover
        color: #4459f3

    &_break
      font-size: 14px
      color: #5b9e5d
      display: flex
      background: #f4ffe7
      align-items: center

      &:hover
        color: #0f8b3e

  & &__itemIcon
    height: 16px
    width: 16px
    margin-right: 6px
    vertical-align: middle
    font-size: 16px
    line-height: 16px

    svg
      font-size: 16px

.Electron.AppHome
  .DailyCalendar
    height: calc(100vh - $electronHeaderHeight)
