@import "../../variables.sass"

.Onboarding
  min-height: 100vh
  display: flex
  flex-direction: row
  width: 100%
  align-items: stretch

.Primary
  width: 55%
  border-right: 1px solid #F1F1F1
  display: flex
  flex-direction: column
  align-items: stretch
  background: white

.Step
  flex: 1
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: stretch
  text-align: center
  position: relative

  h1
    font-size: 16px
    line-height: 1.5em
    margin-top: 4px
    margin-bottom: 4px
    font-weight: 500

  h2
    margin: 4px 0px 4px
    font-size: 14px
    font-weight: 400

  .Content
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative
    overflow-y: auto
    margin: 48px auto
    max-width: 480px

.Secondary
  width: 45%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background: $mainColor
  color: white

.Demo
  display: flex
  flex-direction: column
  margin: 48px
  max-width: 640px

  h1
    font-size: 36px

.Bottom, .Top
  color: transparentize($mainColor, 0.4)
  background: white
  position: sticky
  bottom: 0px
  flex: 0
  width: 100%
  border-top: 1px solid transparentize($mainColor, 0.9)
  border-radius: 0px 0px 12px 12px
  padding: 24px 24px
  display: flex
  flex-direction: row-reverse
  align-items: center
  justify-content: space-between

.Top
  border-radius: 12px 12px 0px 0px
  border-top: 0px
  border-bottom: 1px solid transparentize($mainColor, 0.9)

@media (max-width: 640px)
  .Onboarding
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start

    .Primary, .Secondary
      width: 100%

      .Demo
        margin-bottom: 0px

        h1
          font-size: 24px

      br
        display: none

    .Primary
      flex: 1

  .Step
    border: 0px
    background: white
    margin: 0px 24px
    padding: 0px
    min-height: auto
    min-width: auto

    .Content
      width: unset
      max-width: 100%
      margin: 16px auto
