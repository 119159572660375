$systemFontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
$fontFamily: neue-haas-grotesk-text, $systemFontFamily
$mainFont: 18px
$accentFont: neue-haas-grotesk-text, $systemFontFamily
$pane-bg: rgb(52 64 76 / 80%)

$mainColor: #343141
$mainColorDark: #3d3b44
$secondaryColor: #d6dce2
$primaryGray: #58585c
$secondaryGray: #B2B2B2
$primaryBackground: #FFFFFF
$pageBackgroundDefault: #eee
$borderColor: #9999aa44
// Charts
$chartDescriptionColor: #777777

// Menu
$menuMinWidth: 180px

// Team management
$teamMemberInvited: #F584DE
$teamMemberActive: #118811
$teamMemberInactive: #555555
$teamMemberDeactivated: #F41089

$electronHeaderHeight: 28px
