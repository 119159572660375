.SharingInfo
  align-self: flex-start
  margin-bottom: 48px
  position: absolute
  left: 32px
  top: 24px
  text-align: left

  div
    display: flex

  img
    width: 32px
    height: 32px
    margin-left: 8px

  a
    color: white
    font-weight: bold

  &__powered
    color: rgba(255, 255, 255, 0.6)
    margin-top: 8px

  .SharingInfo__source
    line-height: 1.2em
    color: white

.InActivitySharingButton
  position: absolute
  top: 24px
  right: 32px

.SharingBox
  border-radius: 8px

  .SharingBox__name
    margin: 16px 0px 0px

  &__link
    height: 48px
    margin-top: 16px
    border-radius: 4px
    padding: 0px 16px
    background: #e8e8e8
    color: #555
    font-size: 14px
    display: flex
    align-items: center
    justify-content: space-between
