@import "../../../variables.sass"

.AppFrame
  .PageSubtitle
    margin-bottom: 0
    line-height: 2

.TeamPage
  position: relative
  display: flex

  &__content
    padding: 32px 48px

  .PageTitleGrid
    display: flex
    flex: 1
    align-items: center
    .PageTitle
      max-width: 50%
    .CompanyPicker
      margin-left: auto
      min-width: 50%
