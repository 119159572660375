@import "../../../variables.sass"

$grid-gap: 1px
$grid-height: 480px
$text-color-light: rgba(0, 0, 0, 0.4)
$text-color-primary: rgba(0, 0, 0, 0.7)
$grid-background-color: rgb(211, 205, 198)
$grid-border: rgba(222, 222, 222, 0.5)
$grid-foreground-color: white
$space-sm: 4px
$space-md: 8px
$space-lg: 16px

.calendar-root
  width: 100%

  .navigation-header
    display: flex

  .navigation-header > *
    margin-right: $space-md

  .days-of-week,
  .days-grid
    width: 100%
    box-sizing: border-box
    display: grid
    grid-template-columns: repeat(7, 1fr)
    grid-column-gap: $grid-gap
    grid-row-gap: $grid-gap

    /* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
    .day-of-week-header-cell
      color: $text-color-primary
      background-color: $grid-foreground-color
      padding: $space-md 0
      min-width: 0
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      text-align: right

  .days-grid
    min-height: $grid-height
    position: relative
    background-color: $grid-background-color

    .day-grid-item-container
      position: relative
      background-color: $grid-foreground-color
      display: flex
      flex-direction: column

    .day-grid-item-container:not(.current-month) .day-grid-item-header
      color: $text-color-light

    /* Position the day label within the day cell */
    .day-grid-item-container .day-grid-item-header
      color: $text-color-primary
      padding: $space-md
      padding-bottom: 0
      flex-shrink: 0
      font-weight: bold

    .day-grid-item-container > .day-content-wrapper
      flex: 1
      min-height: 0
      position: relative
      // border: 1px solid $grid-border
      border-radius: 5px
      text-align: right
      justify-content: space-between

      .day-content
        min-height: 0
        text-align: left
        font-size: 12px
      .day-content-energy
        padding: 0
        margin-right: 4px
        background-color: $mainColor
        color: $primaryBackground
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        padding-top: 5px
      .day-content-energy.s0
        min-height: auto
      .day-content-energy.s1
        min-height: 12px
      .day-content-energy.s2
        min-height: 24px
      .day-content-energy.s3
        min-height: 32px
      .day-content-energy.s4
        min-height: 48px
      .day-content-energy.s5
        min-height: 64px
      .day-content-energy-text
        transform: rotate(-90deg)
        font-weight: bolder
        max-width: 18px
      .day-content-entries
        cursor: pointer
        overflow: hidden
        white-space: nowrap
        min-height: 48px
        max-height: 64px
        max-width: 64px
        @media (max-width: 2560px)
          max-width: 144px
        @media (max-width: 1440px)
          max-width: 128px
        @media (max-width: 1280px)
          max-width: 96px
        @media (max-width: 1024px)
          max-width: 64px
        @media (max-width: 768px)
          max-width: 42px
        @media (max-width: 425px)
          max-width: 24px
        div > span
          max-height: 64px
          overflow: hidden
          text-overflow: ellipsis

.content--stack
  .content--description
    width: 80%
  .content--created_at
    text-align: right
    width: 20%
