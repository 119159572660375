@import "../../variables.sass"

.SignIn-Wrapper
  display: flex
  flex-direction: row
  align-items: stretch
  min-height: 100vh
  box-sizing: border-box

  .SignIn-Right
    width: 50%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

  .SignIn-Left
    width: 50%
    background: #222
    color: white
    padding: 48px 24px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    &__content
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start

      .logoV
        height: 60px

.SignInButtons
  max-width: 360px
  margin: 0 auto

.SignIn
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: center
  margin: 32px 0px

  &.Narrow
    flex-direction: column

  h3
    margin: 0px
    text-align: left

  .SignInButton
    height: 40px
    width: 100%
    max-width: 400px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .MuiButton-outlined
      background: white
      color: rgba(0, 0, 0, 0.87)
      border: 1px solid #ddd
      text-transform: none
      padding: 6px 15px
      border-radius: 30px
      font-weight: 500
      font-size: 14px

      &:hover
        background: rgba(97, 31, 105, 0.05)
        border: 1px solid rgba(97, 31, 105, 0.1)
        color: rgba(0, 0, 0, 0.87)

      .Label
        flex: 1
        text-align: center

  .MuiDivider-root
    margin: 16px 16px

  .Google
    height: 40px
    max-width: 400px
    width: 100%

  .Email
    width: 100%

    .MuiOutlinedInput-root
      background-color: white

.Terms
  font-size: 0.833333em
  color: rgba(0, 0, 0, 0.5)
  margin: 16px auto

@media (max-width: 760px)
  .SignIn-Wrapper
    flex-direction: column
    justify-content: flex-start

    .SignIn-Left, .SignIn-Right
      width: 100%
      padding: 24px 64px
      text-align: center

    .Branding
      img
        height: 60px

    .SignIn-Right
      &__content
        margin: 0px auto
        max-width: 240px
        margin-bottom: 16px

  .SignIn
    flex-direction: column
    margin: 0px auto

    .MuiDivider-root
      margin: 16px 0px

  .Terms
    width: 80%
