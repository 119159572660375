
.AppHome2023
  min-width: 100vw
  min-height: 100vh
  display: flex

  .PageHeader
    max-width: 1280px

  &__content
    padding: 16px 48px

  .PromptCard
    word-break: break-all

.Mobile
  .AppHome2023
    &__header
      margin-top: 0px

    &__content
      padding: 16px 16px
