@import "../variables.sass"

.Timer
  cursor: pointer

  &.inProgress
    position: absolute
    bottom: 24px

  .pane
    height: 48px
    min-width: 160px
    border-radius: 24px
    background: $pane-bg
    position: relative
    font-size: 18px
    font-weight: 600
    color: white
    transition: background 0.6s

    &:hover
      background: lighten($pane-bg, 10%)
      transition: background 0.6s

    div, .startWrapper
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center

    .startWrapper
      padding-left: 24px

      &Single
        padding-right: 24px

    .startTimer:hover
      // off
      div.start.default
        padding: 0px 8px
        color: rgba(255, 255, 255, 0.3)
        background: rgba(0, 0, 0, 0)
        font-weight: 600
        transition: padding 0.6s, background 0.6s

        // on
        &:hover
          padding: 0px 24px
          color: white
          font-weight: 800
          background: rgba(0, 0, 0, 0.5)

    .startTimer
      padding-left: 8px
      padding-right: 2px

      // off
      div.start
        display: flex
        margin-left: 16px
        padding: 0px 8px
        height: 44px
        border-radius: 24px
        color: rgba(255, 255, 255, 0.3)
        background: rgba(0, 0, 0, 0)
        white-space: nowrap
        transition: padding 0.6s, background 0.6s

        &.last
          padding-right: 24px

        // on
        &.default, &:hover
          padding: 0px 24px
          color: white
          font-weight: 800
          background: rgba(0, 0, 0, 0.5)
          transition: padding 0.6s, background 0.6s

  .startTip
    margin-top: 24px

@media (max-width: 800px)
  .Timer
    &.inProgress
      position: absolute
      right: 32px

@media (max-width: 480px)
  .Timer
    .pane
      div
        font-size: 14px

      .startWrapper
        padding-left: 0px
        .startTimer
          display: none
          div.start
            padding: 0px 4px
