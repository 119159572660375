$size: 128px
$duration: 12.8s
$curve: cubic-bezier(0.1, 0, .1, 1)
$gradient1: linear-gradient(#7bcfffbb, #8bc7ffaa)
$gradient2: linear-gradient(#6fb4f5bb, #b5d4ff5b)

.breath
  height: $size
  width: $size
  animation: breath $duration cubic-bezier(0.5, 0, 0.5, 1) infinite

  .circle
    height: $size
    width: $size
    border-radius: 50%
    position: absolute
    mix-blend-mode: screen
    transform: translate(0, 0)

    &:nth-child(1)
      animation: circle-1 $duration $curve infinite
      background: $gradient1

    &:nth-child(6)
      animation: circle-6 $duration $curve infinite
      background: $gradient1

    &:nth-child(4)
      animation: circle-4 $duration $curve infinite
      background: $gradient2

    &:nth-child(2)
      animation: circle-2 $duration $curve infinite
      background: $gradient2

    &:nth-child(5)
      animation: circle-5 $duration $curve infinite
      background: $gradient2

    &:nth-child(3)
      animation: circle-3 $duration $curve infinite
      background: $gradient1

@keyframes circle-1
  0%
    transform: translate(0, 0)

  50%
    transform: translate(-35px, -50px)

  100%
    transform: translate(0, 0)

@keyframes circle-2
  0%
    transform: translate(0, 0)

  50%
    transform: translate(35px, 50px)

  100%
    transform: translate(0, 0)

@keyframes circle-3
  0%
    transform: translate(0, 0)

  50%
    transform: translate(-60px, 0)

  100%
    transform: translate(0, 0)

@keyframes circle-4
  0%
    transform: translate(0, 0)

  50%
    transform: translate(60px, 0)

  100%
    transform: translate(0, 0)

@keyframes circle-5
  0%
    transform: translate(0, 0)

  50%
    transform: translate(-35px, 50px)

  100%
    transform: translate(0, 0)

@keyframes circle-6
  0%
    transform: translate(0, 0)

  50%
    transform: translate(35px, -50px)

  100%
    transform: translate(0, 0)

@keyframes breath
  0%
    transform: scale(.25) rotate(180deg)

  25%
    transform: scale(0.99) rotate(0deg)

  50%
    transform: scale(1.01) rotate(0deg)

  75%
    transform: scale(.25) rotate(180deg)

  100%
    transform: scale(.25) rotate(180deg)

.breath-subtitle
  animation: breath-subtitle-distance $duration infinite

  .breath-text
    position: relative
    animation: breath-subtitle-fade $duration infinite

@keyframes breath-subtitle-distance
  0%
    transform: translate(0, -32px)
  50%
    transform: translate(0, 64px)
  100%
    transform: translate(0, -32px)

@keyframes breath-subtitle-fade
  0%
    color: #ffffff00
    top: 0px
  6.25%
    color: #ffffffff
    top: 0px
  12.5%
    color: #ffffffff
    top: 0px
  18.75%
    color: #ffffff00
    top: 0px
  25%
    color: #ffffff00
    top: -30px
  31.25%
    color: #ffffffff
    top: -30px
  37.5%
    color: #ffffffff
    top: -30px
  43.75%
    color: #ffffff00
    top: -30px
  50%
    color: #ffffff00
    top: -60px
  56.25%
    color: #ffffffff
    top: -60px
  62.5%
    color: #ffffffff
    top: -60px
  68.75%
    color: #ffffff00
    top: -60px
  75%
    color: #ffffff00
    top: -90px
  81.25%
    color: #ffffffff
    top: -90px
  87.5%
    color: #ffffffff
    top: -90px
  93.75%
    color: #ffffff00
    top: -90px
  100%
    color: #ffffff00
    top: 0px
