@import "./variables.sass"

body
  box-sizing: border-box
  color: #555
  height: 100%
  height: -webkit-fill-available

h1
  font-family: $accentFont
  font-weight: 800

.Loading
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  flex-direction: column
  width: 100vw
  height: 100vh
  background: #ddd
  text-align: center

.Intro
  text-align: center
  margin-bottom: 36px

  h1
    margin: 12px

a
  text-decoration: none
  color: #111

.returnToSlack
  color: black
  text-decoration: none
  background: white
  border-radius: 4px
  padding: 12px 32px
  font-size: 15px
  margin-top: 16px
  display: block
