@import "../../../variables.sass"

$duration: 0.4s

.ReminderSettingsPage
  &__content
    padding: 32px 48px

  &__toggle
    border-radius: 12px
    padding: 24px 16px
    background: white
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: space-between
    flex: 1

    .ReminderSettingsPage__days
      border-radius: 20px
      padding: 4px 8px
      cursor: pointer
      background: transparentize($mainColor, 1)
      transition: background $duration

      &:hover
        background: transparentize($mainColor, 0.9)
        transition: background $duration

      .ReminderSettingsPage__day, .ReminderSettingsPage__selectedDay
        color: #bbb
        text-align: center
        flex: 1
        width: 18px
        font-size: 14px
        position: relative

      .ReminderSettingsPage__selectedDay
        color: #5b5b5b

        &::before
          transform: translateX(-50%)
          border-radius: 2px
          position: absolute
          background: $mainColor
          bottom: -1px
          height: 2px
          content: ''
          width: 3px
          left: 50%

    .MuiButton-root
      color: #5b5b5b
      border-radius: 30px
      padding: 4px 14px
      background: transparentize($mainColor, 1)
      transition: background $duration
      font-size: 14px

      &:hover
        background: transparentize($mainColor, 0.9)
        transition: background $duration

  &__activityIcon
    height: 32px

  &__channel
    color: #888

    &Icon
      height: 18px

.CustomReminder
  &__days
    border-radius: 20px
    padding: 4px 8px
    cursor: pointer
    background: transparentize($mainColor, 1)
    transition: background $duration

    &:hover
      background: transparentize($mainColor, 0.9)
      transition: background $duration

    .CustomReminder__day, .CustomReminder__selectedDay
      color: #bbb
      text-align: center
      flex: 1
      width: 18px
      font-size: 14px
      position: relative

    .CustomReminder__selectedDay
      color: #5b5b5b

      &::before
        transform: translateX(-50%)
        border-radius: 2px
        position: absolute
        background: $mainColor
        bottom: -1px
        height: 2px
        content: ''
        width: 3px
        left: 50%

  &__timehour.MuiButton-root
    color: #5b5b5b
    border-radius: 30px
    padding: 4px 14px
    background: transparentize($mainColor, 1)
    transition: background $duration
    font-size: 14px

    &:hover
      background: transparentize($mainColor, 0.9)
      transition: background $duration
