@import "../variables.sass"

.Audios
  position: absolute
  bottom: 24px
  left: 32px

  .pane
    height: 48px
    width: 240px
    border-radius: 24px
    background: $pane-bg
    position: relative
    display: flex
    // backdrop-filter: blur(16px)

    .icon
      width: 40px
      height: 100%
      margin-left: 20px
      background-image: url(../assets/sound-off.svg)
      background-size: contain
      background-position: center
      background-repeat: no-repeat
      cursor: pointer
      opacity: 0.8

    .track
      height: 6px
      margin: auto 32px auto 16px
      flex: 1
      border-radius: 3px
      background: rgba(167, 201, 223, 0.24)
      position: relative
      cursor: pointer

      .cursor
        cursor: pointer
        position: absolute
        width: 24px
        height: 24px
        transform: translate(-12px, -9px)
        left: 0%
        top: 0px
        border-radius: 12px
        background: #ffffffee
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.2)
        left: 0%
        transition: left 0.75s

    &.on
      .icon
        background-image: url(../assets/sound-on.svg)

      .track
        .cursor
          transition: left 0.75s

@media (max-width: 480px)
  .Audios
    .pane
      width: auto
      .icon
        margin-right: 20px
      .track
        display: none
