.ProfilePictures
  display: flex
  justify-content: center
  align-items: center
  margin-left: 4px

  .avatar
    width: 32px
    height: 32px
    margin: 0px -4px
    border-radius: 16px
    border: 1px solid white
    overflow: hidden

    img
      object-fit: cover
      width: 100%

    &.large
      width: 48px
      height: 48px
      border-radius: 24px
      border: 2px solid white

  span
    margin-left: 16px
