@import "../../variables.sass"

.HiddenChart
  display: none
  width: 100%

.ChartContainer
  flex: 1
  border-radius: 12px
  padding: 30px
  height: 100%
  width: 100%
  background: $primaryBackground

  .ChartCanvas
    display: flex
    flex: 1
    justify-content: center
    align-items: center
    min-height: 200px
    position: relative
    canvas
      height: 100%
      .chartProgressContainer
        display: flex
        flex: 1
        justify-content: center

  .chartTitle
    color: #676767
    text-transform: uppercase
    font-size: 12px
    font-weight: 500
    letter-spacing: 0.06em

  .chartSubtitle
    font-size: 16px

  .chartDescription
    font-size: 12px
    font-weight: 400

  .chartCustomActions
    margin-top: 30px
    margin-bottom: 30px

    .chartCustomActionsButtonGroup
      $buttonWidth: 48px
      $buttonPadding: 20px
      $buttonHeight: 26px
      flex: 1
      width: 100%
      align-items: center
      justify-content: center

      .chartCustomActionsButton
        font-size: 12px
        text-transform: none
        padding-left: $buttonPadding
        padding-right: $buttonPadding
        // max-width: $buttonWidth
        min-width: $buttonWidth
        max-height: $buttonHeight
        border-left: 1px solid rgba(0,0,0,0.12)
        border-radius: 20px
        margin: 0px 8px

  .chartActionToBeTaken
    font-size: 12px
    align-items: center
    img
      height: 24px
    .chartActionToBeTakenIconContainer
      .chartActionToBeTakenText
        overflow: auto
      .chartActionToBeTakenButton
        margin-left: 0px

.NotEnoughData
  vertical-align: middle
  border-radius: 100px
  background-color: transparentize($mainColor, 0.9)
  width: 80%
